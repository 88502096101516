// extracted by mini-css-extract-plugin
export var contact = "private-label-module--contact--7SMHX";
export var five = "private-label-module--five--VRO3j";
export var four = "private-label-module--four--Be8LY";
export var introClass = "private-label-module--intro-class--OvuDw";
export var layout = "private-label-module--layout--8Utwt";
export var one = "private-label-module--one--ovda5";
export var privateLabelIntro = "private-label-module--private-label-intro--PcRdL";
export var sectionRatio = "private-label-module--section-ratio--gQu4D";
export var textContent = "private-label-module--text-content--OGbrI";
export var three = "private-label-module--three--HSj3v";
export var title = "private-label-module--title--Shim3";
export var titleContact = "private-label-module--title-contact--1WzLb";
export var two = "private-label-module--two--P596X";