import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    title,
    privateLabelIntro,
    one,
    two,
    three,
    four,
    five,
    textContent,
    sectionRatio,
    titleContact,
    contact,
    introClass,
} from './private-label.module.scss';
import { IBannersCollection } from '../models/banner.model';
import { ISitePageContext } from '../models/site-page.model';
import { IConsent } from '../models/consent.model';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';
import { getCollectionByMap } from '../utils/get-collection-by-map';
import { config } from '../config';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import SectionRow from '../components/organisms/section-row';
import Title from '../components/atoms/title';
import Markdown from '../components/hoc/markdown';
import Banner from '../components/organisms/banner';
import ContactForm from '../components/organisms/contact-form';
import PrivateLabelIntro from '../components/organisms/private-label-intro';

const { formContexts } = config;

interface IPrivateLabelProps {
    readonly data: {
        allBannersCollection: { edges: { node: IBannersCollection }[] };
        allConsent: { edges: { node: IConsent }[] };
    };
    readonly pageContext: ISitePageContext;
}

const PrivateLabel: React.FC<IPrivateLabelProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { allBannersCollection, allConsent } = data;

    const collections = allBannersCollection.edges.map(({ node }) => node);
    const consents = allConsent.edges.map(({ node }) => node);

    const bannerPrivateLabelToPlace = {
        mainBanner: 'privateLabel',
        oneBanner: 'privateOne',
        twoBanner: 'privateTwo',
        threeBanner: 'privateThree',
        fourBanner: 'privateFour',
        fiveBanner: 'privateFive',
    };

    const {
        mainBanner,
        oneBanner,
        twoBanner,
        threeBanner,
        fourBanner,
        fiveBanner,
    } = getCollectionByMap(collections, bannerPrivateLabelToPlace);

    return (
        <>
            <SEO title={t('private.label.title')} />
            <MainLayout
                className={layout}
                bannersData={mainBanner && { banner: mainBanner, titleData: { Tag: 'h1' } }}
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(pageContext)],
                }}
            >
                <Title
                    className={title}
                    direction={'reverse'}
                    intro={t('private.label.title.intro')}
                >
                    Private Label
                </Title>

                <PrivateLabelIntro className={privateLabelIntro} />

                <SectionRow
                    className={one}
                    isReversed={true}
                    title={t('private.one.title')}
                    titleData={{ Tag: 'h3' }}
                    narrowContent={
                        <Markdown className={textContent}>{t('private.one.content')}</Markdown>
                    }
                    wideContent={
                        oneBanner && (
                            <Banner banner={oneBanner} ratioClass={sectionRatio} onlyImage={true} />
                        )
                    }
                />
                <SectionRow
                    className={two}
                    title={t('private.two.title')}
                    titleData={{ Tag: 'h3' }}
                    narrowContent={
                        <Markdown className={textContent}>{t('private.two.content')}</Markdown>
                    }
                    wideContent={
                        twoBanner && (
                            <Banner banner={twoBanner} ratioClass={sectionRatio} onlyImage={true} />
                        )
                    }
                />
                <SectionRow
                    className={three}
                    isReversed={true}
                    title={t('private.three.title')}
                    narrowContent={
                        <Markdown className={textContent}>{t('private.three.content')}</Markdown>
                    }
                    wideContent={
                        threeBanner && (
                            <Banner
                                banner={threeBanner}
                                ratioClass={sectionRatio}
                                onlyImage={true}
                            />
                        )
                    }
                />
                <SectionRow
                    className={four}
                    title={t('private.four.title')}
                    narrowContent={
                        <Markdown className={textContent}>{t('private.four.content')}</Markdown>
                    }
                    wideContent={
                        fourBanner && (
                            <Banner
                                banner={fourBanner}
                                ratioClass={sectionRatio}
                                onlyImage={true}
                            />
                        )
                    }
                />
                <SectionRow
                    className={five}
                    isReversed={true}
                    title={t('private.five.title')}
                    narrowContent={
                        <Markdown className={textContent}>{t('private.five.content')}</Markdown>
                    }
                    wideContent={
                        fiveBanner && (
                            <Banner
                                banner={fiveBanner}
                                ratioClass={sectionRatio}
                                onlyImage={true}
                            />
                        )
                    }
                />

                <SectionRow
                    className={contact}
                    mobileLayout={3}
                    narrowContent={
                        <Title
                            className={titleContact}
                            direction={'reverse'}
                            size="large"
                            introClass={introClass}
                            intro={t('private.form.contact')}
                        >
                            {t('contact.form.title')}
                        </Title>
                    }
                    wideContent={
                        <>
                            <ContactForm
                                consents={consents}
                                formContext={formContexts.privateLabel}
                                disclaimer={t('private.label.form.disclaimer')}
                            />
                        </>
                    }
                />
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!, $placesIds: [String!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allBannersCollection(filter: { placeId: { in: $placesIds } }) {
            edges {
                node {
                    ...bannersCollectionFields
                }
            }
        }

        allConsent(filter: { forms: { elemMatch: { systemName: { eq: "form-private-label" } } } }) {
            edges {
                node {
                    ...consentFields
                }
            }
        }
    }
`;

export default PrivateLabel;
