import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { privateLabel, item } from './private-label-intro.module.scss';
import ExperienceIcon from '../../assets/images/svg/experience.svg';
import ManufactureIcon from '../../assets/images/svg/manufacture.svg';
import FormulaIcon from '../../assets/images/svg/formula.svg';
import BottleIcon from '../../assets/images/svg/bottle.svg';
import DesignIcon from '../../assets/images/svg/design.svg';

import GalleryCard, { IGalleryCardProps } from '../molecules/gallery-card';

interface IQualitiesGalleryProps {
    className?: string;
}

const PrivateLabelIntro: React.FC<IQualitiesGalleryProps> = ({ className = '' }) => {
    const { t } = useI18next();

    const labels: IGalleryCardProps[] = [
        {
            Icon: ExperienceIcon,
            content: t('private.card.one.content'),
        },
        {
            Icon: ManufactureIcon,
            content: t('private.card.two.content'),
        },
        {
            Icon: FormulaIcon,
            content: t('private.card.three.content'),
        },
        {
            Icon: BottleIcon,
            content: t('private.card.four.content'),
        },
        {
            Icon: DesignIcon,
            content: t('private.card.five.content'),
        },
    ];

    return (
        <div className={`${privateLabel} ${className}`}>
            {labels.map((label, index) => {
                return <GalleryCard className={item} key={`label-card-${index}`} {...label} />;
            })}
        </div>
    );
};

export default PrivateLabelIntro;
